import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Script} from 'gatsby';
import { filter, reverse, sortBy } from 'lodash';
import { Helmet } from 'react-helmet';
import slugify from 'slugify';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import Layout from 'components/layout';
import PageHeader from 'components/page-header';
import PageContent from 'components/page-content';
import IOMenu from 'containers/io-menu';
import buildSeoData from '../helpers/buildSeo';

const PageTemplate = ({ data, location }) => {
  const {
    pageColor,
    slug,
    title,
    seoTitle,
    seoDescription,
    seoImageOptional,
    header,
    content,
  } = data.contentfulPage;

  const allVolunteers = reverse(
    sortBy(data.allContentfulJournalPost.edges, [i => new Date(i.node.date)])
  );

  const seoDescriptionFilled = seoDescription && seoDescription.seoDescription;

  const seoFieldsObject = {
    title: seoTitle,
    ...(seoDescriptionFilled && { description: seoDescription.seoDescription })
  };
  
  const generateDefaultSeo = {
    title,
    description: documentToPlainTextString(
      (header?.description?.raw || content?.raw) || ''
    ).substring(0, 160)
  };
  
  const seoData = buildSeoData(seoFieldsObject, generateDefaultSeo);
  const { title: pageTitleFull, description: fullDescription } = seoData;
  
  const imageUrl =
    (seoImageOptional &&
      `${seoImageOptional.gatsbyImageData.images.fallback.src}`) ||
    (header &&
      header.image &&
      `${header.image.gatsbyImageData.images.fallback.src}`) ||
    null;

  return (
    <>
    <Layout menuColor={'black'} location={location}>
      <Helmet>
        {pageTitleFull && <meta content={pageTitleFull} property="og:title" />}
        {pageTitleFull && <meta content={pageTitleFull} name="twitter:title" />}
        {pageTitleFull && <title>{pageTitleFull}</title>}
        {fullDescription && (
          <meta content={fullDescription} name="description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} property="og:description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} name="twitter:description" />
        )}
        {pageTitleFull && (
          <meta content={pageTitleFull} name="twitter:text:title" />
        )}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
        {imageUrl && <meta content={imageUrl} name="twitter:image" />}
        {imageUrl && <meta content="1024" name="twitter:image:width" />}
        {imageUrl && <meta content="512" name="twitter:image:height" />}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
      </Helmet>

      <div className={`bg-white ${slug}`}>
        <IOMenu withImage={header.image ? true : false}>
          <PageHeader
            align={!header.image && 'left'}
            size={!header.image && 'large'}
            pageColor={slugify(pageColor, { lower: true })}
            pageLinks={filter(content.references, item => {
              return item.type && item.type === 'Scroll Anchor';
            })}
            // pageLinks={filter(JSON.parse(content.raw).content, item => {
            //   return (
            //     item.data.target &&
            //     item.data.target.sys.contentType &&
            //     item.data.target.sys.contentType.sys.id ===
            //       'blockScrollAnchor' &&
            //     item.data.target.fields.type['en-US'] === 'Scroll Anchor'
            //   );
            // })}
            {...header}
          />
        </IOMenu>
        <PageContent
          content={content}
          pageColor={slugify(pageColor, { lower: true })}
          data={allVolunteers}
        />
      </div>
      <Script id="mc-embedded-global-id">{`
        const subscribeButton = document.getElementById('mc-embedded-subscribe');
        const emailInput = document.getElementById('mce-EMAIL');
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        const checkboxGroup = document.getElementById('mc-checkbox-group');

        if (!subscribeButton || !emailInput || checkboxes.length === 0 || !checkboxGroup) {
          throw new Error('Uno o más elementos necesarios no están disponibles.');
        }

        // Helper function to update button state and styles
        function updateButtonState() {
          const emailFilled = emailInput.value.trim() !== '';
          const checkboxChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);
          const isFormValid = emailFilled && checkboxChecked;

          subscribeButton.disabled = !isFormValid;

          if (isFormValid) {
            subscribeButton.classList.remove('disabled');
          } else {
            subscribeButton.classList.add('disabled');
          }
        }

        // Initial button state and styles
        subscribeButton.classList.add('disabled');

        // Attach event listeners
        emailInput.addEventListener('input', updateButtonState);
        checkboxes.forEach(checkbox => checkbox.addEventListener('change', updateButtonState));

        updateButtonState();
      `}</Script>
    </Layout>
    </>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default PageTemplate;

export const query = graphql`
  query PageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      slug
      seoTitle
      seoDescription {
        seoDescription
      }
      seoImageOptional {
        gatsbyImageData(width: 1024, height: 512)
      }
      pageColor
      header {
        title
        drawing
        cta {
          text
          link
          caption
          opensNewTab
        }
        image {
          gatsbyImageData(width: 1500, placeholder: BLURRED)
          #   fluid(maxWidth: 1500) {
          #     ...GatsbyContentfulFluid_withWebp
          #   }
          #   fixed(width: 1024, height: 512) {
          #     src
          #   }
        }
        cropCoordinatesX
        cropCoordinatesY
        description {
          raw
        }
        linksSection {
          id
          text
          link
          overlayColor
          icon {
            gatsbyImageData(width: 600)
          }
          backgroundImage {
            gatsbyImageData(width: 1500)
          }
          opensNewTab
        }
      }
      content {
        raw
        references {
          __typename
          ... on ContentfulAList {
            contentful_id
            columnCount
            includeIcon
            list {
              raw
            }
            textColor
          }
          ... on ContentfulAButton {
            contentful_id
            text
            link
            asset {
              id
              file {
                url
              }
            }
            opensNewTab
          }
          ... on ContentfulMailchimpEmbed {
            contentful_id
            label
            code {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulASpacer {
            contentful_id
            height
            includeDivider
          }
          ... on ContentfulUtilityBlock {
            contentful_id
            text
            type
          }
          ... on ContentfulAnImageText {
            contentful_id
            image {
              id
              description
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
            imageSide
            imageType
            textDescription: text {
              raw
            }
          }
          ... on ContentfulAPullquote {
            contentful_id
            image {
              id
              description
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
            quote {
              id
              quote
            }
            attribution
          }
          ... on ContentfulAnImageGroupSlideshowCollage {
            contentful_id
            images {
              id
              description
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
            displayAs
            blockWidth
            showCaption
          }
          ... on ContentfulSubscribeAndSocial {
            contentful_id
            facebookLink
            instagramLink
            twitterLink
            linkedinLink
            youtubeLink
            newsletterTitle
            socialLinksTitle
          }
          ... on ContentfulFeaturedVolunteers {
            contentful_id
            featuredVoluteersHeading
          }
          ... on ContentfulAList {
            contentful_id
            columnCount
            includeIcon
            list {
              raw
            }
            textColor
          }
          ... on ContentfulListOfPeopleGrid {
            contentful_id
            listOfPeople {
              id
              image {
                id
                gatsbyImageData(width: 1500, placeholder: BLURRED)
              }
              text {
                raw
              }
            }
          }
          ... on ContentfulContactNdc {
            contentful_id
            title
            office1 {
              id
              childMarkdownRemark {
                html
              }
            }
            office2 {
              id
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulAsset {
            contentful_id
            title
            description
            file {
              url
              fileName
              contentType
            }
            gatsbyImageData(width: 1500, placeholder: BLURRED)
          }
          ... on ContentfulAnImageOrVideo {
            contentful_id
            image {
              id
              description
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
            showCaption
            videoUrl
            blockWidth
          }
        }
      }
    }
    allContentfulJournalPost(
      filter: { type: { in: "Volunteer" }, date: { ne: null } }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          id
          title
          slug
          type
          date
          thumbnailImage {
            gatsbyImageData(width: 2000)
          }
        }
      }
    }
  }
`;
